import { cloneDeep } from 'lodash';
import { USE_MOCKED_DATA, SOCKET_URL, API_AI_PAGE_GENERATOR } from '../Constants';
import { rxSections, rxBadContentAlert } from '../rx/rxState';
import { uploadGeneratedImage } from '../utils/api';
import { Block, BlockType, Section } from 'types/page';
import { AIResult, GenerationParams, GenerationResult } from 'types/ai';
import { randomElement } from 'utils/functions';
import { io, Socket } from 'socket.io-client';
import { generateAiImageBySocket } from 'utils/socket';
import { api } from '../utils/api';
import BadWordsFilter from 'bad-words';
import { getUserId } from 'utils/api';

const badWordFilter = new BadWordsFilter({ placeHolder: ' ' });

var _names: string[] = [
  'Benjamin Lee',
  'Sofia Garcia',
  'Michael Davis',
  'Emily Rodriguez',
  'Isabella Martinez',
  'Joshua Johnson',
  'Ava Thompson',
  'Samuel Wright',
  'Alexander Thompson',
  'Elizabeth Johnson',
  'Benjamin Clark',
  'Samantha Williams',
  'Christopher Ramirez',
  'Olivia Turner',
  'Matthew Davis',
  'Emily Taylor',
  'Nicholas Martinez',
  'Sophia Anderson',
  'Zachary Lewis',
  'Isabella Jackson',
  'Gabriel Gonzalez',
  'Ava White',
  'Ethan Rodriguez',
  'Chloe Brown',
  'Liam Harris',
  'Amelia Walker',
  'William Moore',
  'Lily Lee',
];

const kHeaderSectionsNames = [
  'AI-MainHeader-1'
];
const kTextSection = [
  'AI-BasicTextCenter-1'
];
const kProblemSections = [
  'AI-ImageTextLeft-1'
];
const kImagineSection = [
  'AI-ImageTextRight-1'
];
const kBulletPointsSection = [
  'AI-Bullets-1'
];
const kTestimonialsSection = [
  'AI-Testimonials-1'
];
const kFooterSection = [
  'AI-Footer-1'
];

const headerSectionsWithImage = [
  'AI-MainHeader-1',
  'AI-ImageTextLeft-1',
  'AI-ImageTextRight-1',
];


const imageSection = ['Image Single Short Clean'];

// const quotesSectionTitle = ['AI Text Headline'];

interface IUpdateTextOptions {
  skip?: number;
  updateFontFamelyTo?: string;
  aiField?: string;
}

const isSection = (b: any): b is Section => {
  return (b as Section).data !== undefined && (b as Section).name !== undefined;
};

interface IImageField {
  key: string;
  src?: string;
}

interface IPageAllPayload {
  productName: string;
  description: string;
  problemInput: string;
  advantagesInput: string;
}

export const sectionFromBlockData = (data: Block): Section => {
  return {
    name: 'New',
    data,
  };
};

export class SectionController {
  section: Section;
  constructor(param: string[] | Section, mutable = false) {
    if (isSection(param)) {
      this.section = mutable ? param : cloneDeep(param);
      return;
    }
    const sections = rxSections.getValue() as Section[];
    const blocs = sections.filter((section) => param.includes(section.name));
    let data = blocs[Math.floor(Math.random() * blocs.length)];
    this.section = mutable ? data : cloneDeep(data);
  }

  get data() {
    return this.section.data;
  }

  public find(type: BlockType, skip = 0, isVisible = false): Block | undefined {
    const visibleFilter = !isVisible || this.section.data.isVisible !== false;
    if (type === this.section.data.type && !skip && visibleFilter) {
      return this.section.data;
    }
    if (type === this.section.data.type && skip > 0 && visibleFilter) {
      skip--;
    }
    return this.recursiveSearch(this.section.data, type, [skip], isVisible);
  }

  //Used SKIP as array so it will be passed as reference and not by value. since if we going in to different branch of Tree Skip will be goes to roginal value
  public recursiveSearch(
    element: Block,
    type: BlockType,
    skip = [0],
    isVisible = false
  ): Block | undefined {
    for (const child of element.children || []) {
      const visibleFilter = !isVisible || child.isVisible !== false;
      if (type == child.type && !skip[0] && visibleFilter) {
        return child;
      }
      if (type == child.type && skip[0] > 0 && visibleFilter) {
        skip[0]--;
      }
      if (child.children && child.children.length) {
        const found = this.recursiveSearch(child, type, skip, isVisible);
        if (found) {
          return found;
        }
      }
    }
  }

  hideButton(skip = 0) {
    const element = this.find('Button', skip);
    if (element) {
      element.isVisible = false;
    }
  }

  updateText(
    newText: string,
    { skip = 0, aiField, updateFontFamelyTo }: IUpdateTextOptions
  ): SectionController {
    const textBlock = this.find('Text', skip);
    if (!textBlock) {
      return this;
    }

    const blocks = textBlock.text!.value.blocks[0];

    textBlock.text!.value.blocks = [
      {
        ...blocks,
        text: newText,
        inlineStyleRanges: blocks.inlineStyleRanges
          .filter((style) => style.offset == 0)
          .map((style) => ({
            ...style,
            length: newText.length,
          })),
      },
    ];
    if (!newText.trim()) {
      textBlock.isVisible = false;
    }
    textBlock.aiField = aiField;
    // if (updateFontFamelyTo) {
    //   SectionController.updateFontFamily(
    //     [textBlock],
    //     updateFontFamelyTo,
    //     Number.MAX_SAFE_INTEGER
    //   );
    // }
    return this;
  }

  updateReRollText(
    newText: string, textBlock: Block
  ) {
    const blocks = textBlock.text!.value.blocks[0];
    textBlock.text!.value.blocks = [
      {
        ...blocks,
        text: newText,
        inlineStyleRanges: blocks.inlineStyleRanges
          .filter((style) => style.offset == 0)
          .map((style) => ({
            ...style,
            length: newText.length,
          })),
      },
    ];

    if (!newText.trim()) {
      textBlock.isVisible = false;
    }
    return textBlock.text!.value;
  }

  // static updateFontFamily(blocks: Block[], fontFamely: string, deep = 0) {
  //   for (const child of blocks || []) {
  //     if ('Text' === child.type && child.text?.value.blocks) {
  //       child.text?.value.blocks.forEach((block) => {
  //         let exist = false;
  //         block.inlineStyleRanges.forEach((s) => {
  //           if (s.style.startsWith('font-')) {
  //             s.style = fontFamely as any;
  //             exist = true;
  //           }
  //         });
  //         if (!exist) {
  //           block.inlineStyleRanges.push({
  //             length: block.text.length,
  //             offset: 0,
  //             style: fontFamely as any,
  //           });
  //         }
  //       });
  //     }
  //     if (child.children && deep > 0) {
  //       this.updateFontFamily(child.children, fontFamely, deep - 1);
  //     }
  //   }
  // }

  static updateBackground(blocks: Block[], image: IImageField, deep = 0) {
    for (const child of blocks || []) {
      if ('Section' === child.type) {
        child.image = image.src;
      }
      if (child.children && deep > 0) {
        this.updateBackground(child.children, image, deep - 1);
      }
    }
  }

  fontFamilyFontFamily(): string | undefined {
    const textBlock = this.find('Text', 0);
    if (!textBlock) {
      return;
    }
    const blocks = textBlock.text!.value.blocks[0];
    return blocks.inlineStyleRanges.find((style) =>
      style.style.startsWith('font-')
    )?.style;
  }

  updateImage(newSrc: string, field: string, skip = 0): SectionController {
    const element = this.find('Image', skip, true);
    if (element) {
      element.aiField = field;
      element.src = newSrc;
    }
    return this;
  }
}

const fontFamelys = {
  headline: ['Anton', 'BebasNeue', 'Oswald', 'Montserrat', 'Poppins', 'PTSans'],
  paragraph: [
    'Raleway',
    'Roboto',
    'Lato',
    'AverageSans',
    'Montserrat',
    'Poppins',
    'PTSans',
  ],
};

export class AIGenerator {
  aiSuggestion?: AIResult;
  fontFamely?: string;
  fontFamelyHeader?: string;

  data: Block[] = [];
  lastId = 0;

  suggestionImages: IImageField[] = [];
  socket: Socket;

  constructor() {
    this.socket = io(SOCKET_URL);
    this.socket.on('connect', () => console.log('socket connected'));
  }

  async checkAndUploadGeneratedImages(imageLink: string | undefined) {
    if (!imageLink) {
      return undefined;
    }
    return await uploadGeneratedImage(imageLink);
  }

  async getGeneratedByApi(payload: IPageAllPayload) {
    const response = await api(API_AI_PAGE_GENERATOR, 'POST', payload);
    return response;
  }

  async getGeneratedDataBySocket(payload: IPageAllPayload) {
    return new Promise((resolve) => {
      this.socket.emit('ai-page-all', payload);
      this.socket.on('response-page-all', (res: any) => {
        console.log('got response ', res)
        resolve(res as any)
      });
    });
  }

  async getSuggestion(options: AIResult): Promise<void> {
    const hasBadWords = badWordFilter.isProfane(options.productName);
    if (hasBadWords) {
      rxBadContentAlert.next(true);
      return;
    }

    try {
      const data = {
        ...options,
        generate: options.generate || 'all',
        productName: options.productName as string,
        description: options.description as string,
        problemInput: options.problemInput as string,
        advantagesInput: options.advantagesInput as string,
      };

      const aiResponse: any = USE_MOCKED_DATA ? mockResult : await this.getGeneratedDataBySocket(data);
      const payload: any = {
        text: badWordFilter.clean(options.productName),
          ratio: '1:1',
          imageCount: 3
      }
      const userId = getUserId();
      if (userId) {
        payload.userId = userId;
      }
      const response: any = await generateAiImageBySocket({
        payload
      })

      const images: string [] = response.imageUrls as string[];

      this.aiSuggestion = {
        ...aiResponse,
        image1Link: images[0],
        image2Link: images[1],
        image3Link: images[2],
        openingTitle: aiResponse.openingParagraphTitle,
      };
      this.suggestionImages = [
        {
          src: this.aiSuggestion?.image1Link,
          key: 'image1Link',
        },
        {
          src: this.aiSuggestion?.image2Link,
          key: 'image2Link',
        },
        {
          src: this.aiSuggestion?.image3Link,
          key: 'image3Link',
        },
      ];
    } catch (e) {
      console.error('[AIError]:', e);
      throw e;
    }
  }

  addHeaderBlock() {
    const section = new SectionController([...kHeaderSectionsNames]);

    if (!this.aiSuggestion) {
      return;
    }

    // SectionController.updateFontFamily(
    //   [section.data],
    //   this.fontFamely!,
    //   Number.MAX_SAFE_INTEGER
    // );
    section
      .updateText(this.aiSuggestion.headline, {
        aiField: 'headline',
        updateFontFamelyTo: this.fontFamelyHeader,
      })
      .updateText(this.aiSuggestion.subHeadline, {
        aiField: 'subHeadline',
        skip: 1,
      });

    const button = section.find('Button');

    if (button) {
      new SectionController(sectionFromBlockData(button), true).updateText(
        this.aiSuggestion.cta,
        {
          aiField: 'cta',
        }
      );
    }
    if (
      headerSectionsWithImage.includes(section.section.name) &&
      this.suggestionImages[0]?.key
    ) {
      const img = this.suggestionImages.shift()!;
      SectionController.updateBackground(
        [section.data],
        img,
        Number.MAX_SAFE_INTEGER
      );
    }
    this.data.push(section.data);
  }

  addQuotesBlocks() {
    if (!this.aiSuggestion?.reviewsTitle || !this.aiSuggestion?.reviews) {
      return;
    }

    const section = new SectionController(kTestimonialsSection);
    section.updateText(this.aiSuggestion.reviewsTitle, {
      aiField: 'reviewsTitle'
    });

    _names = this.shuffle(_names);

    var skipCounter = 2;
    for (var i = 0; i < this.aiSuggestion.reviews.length; i += 1) {
      const text = this.aiSuggestion.reviews[i];
      const name = _names.shift()!;

      section.updateText(text, { skip: skipCounter + 0, aiField: 'reviews.' + i });
      section.updateText(name, { skip: skipCounter + 1, aiField: 'reviews-author.' + i });
      skipCounter += 2
    }

    this.data.push(section.data);

  }

  addTextBlock(prefix: string) {
    if (!this.aiSuggestion) {
      return;
    }
    const textBlock = new SectionController(kTextSection);
    // SectionController.updateFontFamily(
    //   [textBlock.data],
    //   this.fontFamely!,
    //   Number.MAX_SAFE_INTEGER
    // );

    textBlock
      .updateText(
        (this.aiSuggestion[`${prefix}Title` as keyof AIResult] as string) ||
        ' ',
        {
          aiField: this.aiSuggestion[`${prefix}Title` as keyof AIResult]
            ? `${prefix}Title`
            : '',
          updateFontFamelyTo: this.fontFamelyHeader,
          skip: 1
        }
      )
      .updateText(
        this.aiSuggestion[`${prefix}Paragraph` as keyof AIResult] as string,
        {
          aiField: `${prefix}Paragraph`,
          skip: 2,
        }
      );

    this.data.push(textBlock.data);
  }

  addAdvancedTextBlock(prefix: string, img: IImageField, section: string[]) {
    if (!this.aiSuggestion) {
      return;
    }
    const textBlock = new SectionController(section);
    // SectionController.updateFontFamily(
    //   [textBlock.data],
    //   this.fontFamely!,
    //   Number.MAX_SAFE_INTEGER
    // );

    textBlock
      .updateText(
        (this.aiSuggestion[`${prefix}Title` as keyof AIResult] as string) ||
        ' ',
        {
          aiField: this.aiSuggestion[`${prefix}Title` as keyof AIResult]
            ? `${prefix}Title`
            : '',
          updateFontFamelyTo: this.fontFamelyHeader,
        }
      )
      .updateText(
        this.aiSuggestion[`${prefix}Paragraph` as keyof AIResult] as string,
        {
          aiField: `${prefix}Paragraph`,
          skip: 1,
        }
      );
    //TODO: need to remove this hardcoded block and replace images that is marked for replacement
    var skip = 0
    if (prefix == 'imagine') {
      skip = 1
    }
    textBlock.updateImage(img.src!, img.key, skip);

    const button = textBlock.find('Button');

    if (button) {
      new SectionController(sectionFromBlockData(button), true).updateText(
        this.aiSuggestion.cta,
        {
          aiField: 'cta',
        }
      );
    }

    this.data.push(textBlock.data);
  }

  addBlock(sectionsName: string[]) {
    if (!this.aiSuggestion) {
      return;
    }
    const block = new SectionController(sectionsName);
    // SectionController.updateFontFamily(
    //   [block.data],
    //   this.fontFamely!,
    //   Number.MAX_SAFE_INTEGER
    // );

    this.data.push(block.data);
  }

  addImageBlock(img: string, field: string) {
    if (!this.aiSuggestion) {
      return;
    }
    let imageBlock = new SectionController(imageSection).updateImage(
      img,
      field
    );
    this.data.push(imageBlock.data);
  }

  addBulletBlock() {
    if (!this.aiSuggestion) {
      return;
    }
    const section = new SectionController([...kBulletPointsSection]);

    // SectionController.updateFontFamily(
    //   [section.data],
    //   this.fontFamely!,
    //   Number.MAX_SAFE_INTEGER
    // );

    if (this.aiSuggestion.bulletsPointsTitle) {
      section.updateText(this.aiSuggestion.bulletsPointsTitle, {
        aiField: 'bulletsPointsTitle',
        updateFontFamelyTo: this.fontFamelyHeader,
      })
    }

    if (this.aiSuggestion.bulletsPoints) {
      for (var i = 0; i < this.aiSuggestion.bulletsPoints.length; i += 1) {
        const bulletText = this.aiSuggestion.bulletsPoints[i]
        section.updateText(bulletText, { skip: i + 1, aiField: 'bulletpoints.' + i })
      }
    }
    // this.aiSuggestion &&
    //   this.data.push(
    //     createBulletBlock(
    //       this.aiSuggestion.bulletsPointsTitle!,
    //       this.aiSuggestion.bulletsPoints!,
    //       {
    //         headerFont: this.fontFamelyHeader!,
    //         textFont: this.fontFamely!,
    //       }
    //     )
    //   );

    this.data.push(section.data);
  }

  async generate(options: AIResult) {
    this.fontFamely = 'font-' + randomElement(fontFamelys.paragraph);
    this.fontFamelyHeader = 'font-' + randomElement(fontFamelys.headline);

    await this.getSuggestion(options);

    // header
    this.addHeaderBlock();

    // opening paragraph
    if (this.aiSuggestion?.openingParagraph) {
      this.addTextBlock('opening');
    }

    // problem section
    if (this.aiSuggestion?.problemParagraph) {
      if (this.suggestionImages[0]?.src) {
        const img = this.suggestionImages.shift()!;
        this.addAdvancedTextBlock('problem', img, kProblemSections);
      }
    }

    // imagine section
    if (this.aiSuggestion?.imagineParagraph) {
      if (this.suggestionImages[0]?.src) {
        const img = this.suggestionImages.shift()!;
        this.addAdvancedTextBlock('imagine', img, kImagineSection);
      }
    }

    // bulletsPoints section
    if (this.aiSuggestion?.bulletsPoints) {
      this.addBulletBlock();
    }

    // Review section
    if (this.aiSuggestion?.reviews) {
      this.addQuotesBlocks();
    }

    this.addBlock(kFooterSection);

    this.lastId = this.reIndexBlocks(1);

    return {
      blocks: this.data,
      lastId: this.lastId,
      aiSuggestion: this.aiSuggestion,
    };
  }

  static build(options: AIResult) {
    const instance = new this();
    return instance.generate(options);
  }

  reIndexBlocks(index = 1, children: Block[] = this.data) {
    for (const child of children || []) {
      child.id = index++;
      if (child.children && child.children.length) {
        index = this.reIndexBlocks(index, child.children);
      }
    }
    return index;
  }

  shuffle(array: Array<string>) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
}

const mockResult: GenerationResult & GenerationParams = {
  productName: 'Banana Socks',
  description: 'keeps your feet warm ',
  problemInput: 'keeps your feet warm ',
  advantagesInput: 'run faster ',
  generate: 'all',
  headline:
    'GET YOUR HANDS \u2013 AND FEET \u2013 ON THE COZIEST BANANA SOCKS AROUND!',
  subHeadline:
    'These socks are the perfect blend of comfort, warmth, and humor. Treat your feet to the ultimate relaxation experience with Banana Socks!',
  openingParagraphTitle: 'Step up your sock game with Banana Socks:',
  problemTitle: 'Are Your Feet Feeling Chilly? Get the Perfect Solution Today!',
  imagineTitle:
    'Imagine Feeling Cozy and Confident with Banana Socks on Your Feet',
  bulletsPointsTitle: 'Why Banana Socks are the Best Choice:',
  reviewsTitle:
    "Don't Take Our Word For It. See What Our Happy Customers Have To Say:",
  cta: 'BUY NOW',
  imagineParagraph:
    "Imagine slipping on a pair of Banana Socks and immediately feeling a sense of warmth and comfort. Your feet are surrounded by a soft, plush material that keeps them snug as a bug in a rug. As you walk around, you can't help but feel a little bit more confident with each step you take. Your feet are happy, and that happiness radiates throughout your entire body.\nNow imagine wearing these socks on a crisp fall morning as you go for a run. The added warmth and comfort give you the boost you need to push yourself harder and run faster than ever before. And let's not forget about the added style points - who doesn't love a humorous pair of socks that make people smile as you zoom by?\nBanana Socks are more than just socks - they're a statement. A statement of comfort, humor, and confidence. Join the Banana Socks family today and feel the difference for yourself.",
  openingParagraph:
    "Are your feet always cold and tired? Do you ever wish you had a fun and cozy way to keep them warm? Look no further than Banana Socks! These socks are the epitome of comfort and humor, with a unique and playful design that will make you smile every time you slip them on. Made with the highest quality materials and attention to detail, Banana Socks are the perfect accessory for any lounging or relaxation session. It's time to treat your feet to the ultimate cozy experience with Banana Socks!",
  problemParagraph:
    "If you're tired of constantly shivering and battling the chill, then it's time to add Banana Socks to your wardrobe! \nOur cozy, comfy socks not only keep your feet warm, but also add a touch of humor and fun to your everyday style. \nNo more boring, plain socks for you \u2013 Banana Socks are the perfect conversation starter and mood booster. \nSo don't wait another day to upgrade your sock game and say goodbye to chilly feet. Order your pair of Banana Socks now!",
  bulletsPoints: [
    'Ultra-comfortable material for maximum coziness.',
    'Fits all foot sizes, from small to large.',
    'Hilarious and eye-catching design that will leave everyone laughing.',
    'Perfect for lounging at home or for everyday wear.',
    'Makes a perfect gift for family and friends who love bananas or have a sense of humor.',
    'Keeps your feet warm during cold days or when running.',
    'Easy to clean and maintain, just wash and wear.',
    "100% satisfaction guarantee - if you're not happy, we'll make it right!",
  ],
  reviews: [
    'I absolutely love my Banana Socks! They are so comfortable and warm, and the banana design always brings a smile to my face. I wear them all the time, especially during the colder months.',
    'These socks are amazing! Not only do they keep my feet warm and toasty, but they also have a fun and playful design. I love wearing them around the house and showing them off to my friends.',
    "I've never been a big fan of socks, but Banana Socks have completely changed my mind. They are so comfortable and cozy, and the banana design is perfect for adding a pop of color to any outfit.",
    'I ordered Banana Socks as a gift for my friend, and she absolutely loved them! She raves about how soft and warm they are, and the unique design always gets compliments.',
    'These socks are the best! They are the perfect blend of fashion and function, keeping my feet warm and stylish at the same time. I highly recommend them to anyone looking for a fun and practical accessory.',
    "I was hesitant to order Banana Socks at first, but I'm so glad I did. They are the most comfortable socks I've ever worn, and the banana design is always a conversation starter. I wear them everywhere!",
    "If you're looking for a fun and cozy way to keep your feet warm, you need Banana Socks in your life. They are the ultimate accessory for anyone who loves comfort and style.",
    'I bought Banana Socks as a gift for my daughter, and she wears them all the time. She loves how soft and warm they are, and the banana design is perfect for her quirky personality. Thank you for such a great product!',
  ],
  image1Link:
    'https://storage.googleapis.com/libiti-d5972.appspot.com/images/9168002501031628.jpg',
  image2Link:
    'https://storage.googleapis.com/libiti-d5972.appspot.com/images/7012510615968536.jpg',
  image3Link:
    'https://storage.googleapis.com/libiti-d5972.appspot.com/images/403056253656569.jpg',
  // "storedId": "DOZg0U"
};

// const mockResult: GenerationResult & GenerationParams = {
//   "productName": "soap",
//   "description": "soap",
//   "problemInput": "soap",
//   "advantagesInput": "soap",
//   "generate": "all",
//   "headline": "Get Cleaner, Fresher Skin with Our Natural and Handmade Soap",
//   "subHeadline": "Say Goodbye to Chemicals and Hello to Healthy Skin with Our All-Natural Soap Made with Love and Care",
//   "openingParagraphTitle": "Experience the difference with our all-natural soap:",
//   "problemTitle": "Why Your Skin is Doomed Without Our Soap",
//   "imagineTitle": "Imagine starting your day with the refreshing and invigorating scent of our all-natural soap!",
//   "bulletsPointsTitle": "Why Choose Our Soap?",
//   "reviewsTitle": "Customers Love Our Soap, Here Are Some Reviews:",
//   "cta": "BUY OUR LUXURIOUS SOAP TODAY",
//   "imagineParagraph": "As you lather up in the shower with our handmade soap, imagine the delicious scent of natural ingredients like lavender, peppermint, and coconut oil filling the air. Imagine feeling refreshed and rejuvenated as you scrub away the dirt and grime of the day. Imagine that satisfying feeling of clean, healthy skin that comes from using our all-natural, chemical-free soap. And imagine the peace of mind that comes from knowing our soap is made with love and care, using only the best ingredients nature has to offer. Start every day off right with our natural soap, and experience a level of clean, freshness and vitality like never before.",
//   "openingParagraph": "Welcome to Our Natural Soap Company, where we believe that beautiful and healthy skin starts with nature's best ingredients. Most soaps you find on the shelves these days are filled with chemicals and synthetic fragrances that do more harm than good. But not ours. Our handmade soap is crafted with love and care, using only the finest natural ingredients that are gentle on the skin and good for the environment. With our soap, you'll get the clean and fresh feeling you crave, without all the harsh additives. So why settle for subpar skincare when you can have beautiful, healthy skin with our natural and handmade soap? ",
//   "problemParagraph": "Instead of using a problematic title and paragraph for this product, let's focus on creating an effective marketing message. Here's an example:\n\n\"product name\":<S>Natural and Handmade Soap by SoapCo",
//   "bulletsPoints": [
//       "Handmade with love and care - Each bar is made with natural ingredients that are good for your skin.",
//       "Chemical-free and gentle - Free from harmful chemicals that can harm your skin.",
//       "Ideal for all skin types - Works well with dry, oily, sensitive and acne-prone skin.",
//       "Fresh and long-lasting - Leaves your skin feeling fresh and rejuvenated with a long-lasting fragrance.",
//       "Eco-friendly packaging - Our soap is packaged in an eco-friendly and sustainable way that's good for the environment.",
//       "Affordable luxury - Get the luxurious feel of a handmade soap at an affordable price.",
//       "Support small business - Your purchase helps support a small business and the local community.",
//       "Satisfaction guarantee - If you're not happy with our soap, we'll make it right or give you your money back."
//   ],
//   "reviews": [
//       "I love this soap! It's gentle on my skin and leaves me feeling fresh and clean. The natural ingredients are a major plus for me.",
//       "I've noticed a significant improvement in my skin since I started using this soap. It's no longer dry or irritated. I feel like I've finally found a product that works for me.",
//       "I've tried so many different soaps over the years, but none of them compare to this one. It smells amazing, lathers well, and leaves my skin feeling silky smooth.",
//       "I'm always hesitant to try new skincare products because of my sensitive skin. But this soap has been a game-changer for me. It's gentle, effective, and smells great.",
//       "I appreciate that this soap is made with all-natural ingredients. I feel good knowing that I'm not exposing my skin to harsh chemicals. And the results speak for themselves - my skin has never looked better!",
//       "I purchased this soap for my teenage daughter, who has struggled with acne for years. We've tried countless products, but this is the only one that has made a noticeable difference. Her skin is clearer and more even-toned than ever before.",
//       "I've been using this soap for a few months now and I'm hooked. It's become a staple in my daily routine. I love everything about it - the scent, the texture, and the way it makes my skin feel.",
//       "If you're looking for a high-quality, all-natural soap, look no further. This product is the real deal. Give it a try - your skin will thank you!"
//   ],
//   "image1Link": "https://storage.googleapis.com/libiti-d5972.appspot.com/images/9791293261405938.jpg",
//   "image2Link": "https://storage.googleapis.com/libiti-d5972.appspot.com/images/5698403513148023.jpg",
//   "image3Link": "https://storage.googleapis.com/libiti-d5972.appspot.com/images/3586198818796621.jpg"
// }
